<template>
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect opacity="0.2" x="5" y="10" width="17" height="24" :fill="lineColor" />
    <rect x="4.75" y="9.75" width="34.5" height="24.5" rx="2.25" :stroke="lineColor" stroke-width="1.5" />
    <path
      d="M18.007 17.9927C18.9183 18.9039 18.9183 20.3813 18.007 21.2925C17.0958 22.2037 15.6184 22.2037 14.7072 21.2925C13.796 20.3813 13.796 18.9039 14.7072 17.9927C15.6184 17.0814 17.0958 17.0814 18.007 17.9927Z"
      :stroke="lineColor"
      stroke-width="2"
    />
    <path d="M14.4716 21.5284L9.28613 26.7139" :stroke="lineColor" stroke-width="2" stroke-linecap="round" />
    <rect
      x="9.75732"
      y="26.2422"
      width="3.33333"
      height="3.33333"
      transform="rotate(-45 9.75732 26.2422)"
      :fill="lineColor"
    />
    <rect x="23.3335" y="18.667" width="13.3333" height="6.66667" rx="2" :fill="lineColor" />
    <path
      d="M26.3335 23.6663V20.5199C26.3335 20.44 26.2445 20.3924 26.178 20.4367L25.3335 20.9997"
      :stroke="textColor"
    />
    <path
      d="M28.6665 20.9997C28.6665 20.333 29.3332 20.333 29.6665 20.333C30.4075 20.333 30.6665 21.333 29.9998 21.9997L28.6665 22.9997V23.333H30.6665"
      :stroke="textColor"
    />
    <path
      d="M32 20.333H33.6667L33 21.333C33.6667 21.333 34 21.533 34 22.333C34 23.333 33 23.333 32 23.333"
      :stroke="textColor"
    />
  </svg>
</template>

<script>
import colors from '@/stylesheets/scss/colors.module.scss';
import typography from '@/stylesheets/scss/typography.module.scss';

export default {
  props: {
    selected: { type: Boolean, default: false },
  },
  computed: {
    lineColor() {
      return this.selected ? colors.white : typography.typographyPrimary;
    },
    textColor() {
      return this.selected ? colors.primary : colors.white;
    },
  },
};
</script>
