<template>
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect opacity="0.2" x="10" y="22" width="17" height="24" transform="rotate(-90 10 22)" :fill="lineColor" />
    <rect
      x="9.75"
      y="39.25"
      width="34.5"
      height="24.5"
      rx="2.25"
      transform="rotate(-90 9.75 39.25)"
      :stroke="lineColor"
      stroke-width="1.5"
    />
    <path
      d="M26.0075 9.99266C26.9187 10.9039 26.9187 12.3813 26.0075 13.2925C25.0963 14.2037 23.6189 14.2037 22.7077 13.2925C21.7965 12.3813 21.7965 10.9039 22.7077 9.99266C23.6189 9.08144 25.0963 9.08144 26.0075 9.99266Z"
      :stroke="lineColor"
      stroke-width="2"
    />
    <path d="M22.4716 13.5284L17.2861 18.7139" :stroke="lineColor" stroke-width="2" stroke-linecap="round" />
    <rect
      x="17.7578"
      y="18.2422"
      width="3.33333"
      height="3.33333"
      transform="rotate(-45 17.7578 18.2422)"
      :fill="lineColor"
    />
    <rect x="15.333" y="26.667" width="13.3333" height="6.66667" rx="2" :fill="lineColor" />
    <path d="M18.333 31.6663V28.5199C18.333 28.44 18.244 28.3924 18.1775 28.4367L17.333 28.9997" :stroke="textColor" />
    <path
      d="M20.667 28.9997C20.667 28.333 21.3337 28.333 21.667 28.333C22.408 28.333 22.667 29.333 22.0003 29.9997L20.667 30.9997V31.333H22.667"
      :stroke="textColor"
    />
    <path
      d="M24 28.333H25.6667L25 29.333C25.6667 29.333 26 29.533 26 30.333C26 31.333 25 31.333 24 31.333"
      :stroke="textColor"
    />
  </svg>
</template>

<script>
import colors from '@/stylesheets/scss/colors.module.scss';
import typography from '@/stylesheets/scss/typography.module.scss';

export default {
  props: {
    selected: { type: Boolean, default: false },
  },
  computed: {
    lineColor() {
      return this.selected ? colors.white : typography.typographyPrimary;
    },
    textColor() {
      return this.selected ? colors.primary : colors.white;
    },
  },
};
</script>
