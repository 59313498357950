<template>
  <PdfViewer v-if="documentSrc" :src="documentSrc">
    <template #page="{ component, data }">
      <DrawingOverlay :rects="rects" :editable="editable" @update:rects="handleUpdate" @click:rect="handleClick">
        <component :is="component" :page="data" />
      </DrawingOverlay>
    </template>
  </PdfViewer>
</template>

<script>
import { PdfViewer } from '@/modules/core/components/PdfViewer';
import { DrawingOverlay } from '@/modules/core/components/DrawingOverlay';

import { fieldCoordinatesToRect, rectToFieldCoordinates } from '../tools/viewerCoordinates';

export default {
  components: { PdfViewer, DrawingOverlay },
  props: {
    document: { type: Object, default: null },
    template: { type: Object, default: null },
    editable: { type: Boolean, default: false },
  },
  computed: {
    documentSrc() {
      return this.document?.filePathUrl;
    },
    rects() {
      return (this.template?.fields || []).map(fieldCoordinatesToRect);
    },
  },
  methods: {
    handleClick(rect, index) {
      this.$emit('field-click', rectToFieldCoordinates(rect), index);
    },
    handleUpdate(rects) {
      if (this.template) this.$emit('fields-update', rects.map(rectToFieldCoordinates));
    },
  },
};
</script>
