<template>
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect opacity="0.2" x="10" y="39" width="17" height="24" transform="rotate(-90 10 39)" :fill="lineColor" />
    <rect
      x="9.75"
      y="39.25"
      width="34.5"
      height="24.5"
      rx="2.25"
      transform="rotate(-90 9.75 39.25)"
      :stroke="lineColor"
      stroke-width="1.5"
    />
    <path
      d="M26.0075 25.9927C26.9187 26.9039 26.9187 28.3813 26.0075 29.2925C25.0963 30.2037 23.6189 30.2037 22.7077 29.2925C21.7965 28.3813 21.7965 26.9039 22.7077 25.9927C23.6189 25.0814 25.0963 25.0814 26.0075 25.9927Z"
      :stroke="lineColor"
      stroke-width="2"
    />
    <path d="M22.4716 29.5284L17.2861 34.7139" :stroke="lineColor" stroke-width="2" stroke-linecap="round" />
    <rect
      x="17.7578"
      y="34.2422"
      width="3.33333"
      height="3.33333"
      transform="rotate(-45 17.7578 34.2422)"
      :fill="lineColor"
    />
    <rect x="15.333" y="10.667" width="13.3333" height="6.66667" rx="2" :fill="lineColor" />
    <path d="M18.333 15.6663V12.5199C18.333 12.44 18.244 12.3924 18.1775 12.4367L17.333 12.9997" :stroke="textColor" />
    <path
      d="M20.667 12.9997C20.667 12.333 21.3337 12.333 21.667 12.333C22.408 12.333 22.667 13.333 22.0003 13.9997L20.667 14.9997V15.333H22.667"
      :stroke="textColor"
    />
    <path
      d="M24 12.333H25.6667L25 13.333C25.6667 13.333 26 13.533 26 14.333C26 15.333 25 15.333 24 15.333"
      :stroke="textColor"
    />
  </svg>
</template>

<script>
import colors from '@/stylesheets/scss/colors.module.scss';
import typography from '@/stylesheets/scss/typography.module.scss';

export default {
  props: {
    selected: { type: Boolean, default: false },
  },
  computed: {
    lineColor() {
      return this.selected ? colors.white : typography.typographyPrimary;
    },
    textColor() {
      return this.selected ? colors.primary : colors.white;
    },
  },
};
</script>
