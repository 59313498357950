<template>
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect opacity="0.2" x="22" y="10" width="17" height="24" :fill="lineColor" />
    <rect x="4.75" y="9.75" width="34.5" height="24.5" rx="2.25" :stroke="lineColor" stroke-width="1.5" />
    <path
      d="M34.007 17.9927C34.9183 18.9039 34.9183 20.3813 34.007 21.2925C33.0958 22.2037 31.6184 22.2037 30.7072 21.2925C29.796 20.3813 29.796 18.9039 30.7072 17.9927C31.6184 17.0814 33.0958 17.0814 34.007 17.9927Z"
      :stroke="lineColor"
      stroke-width="2"
    />
    <path d="M30.4716 21.5284L25.2861 26.7139" :stroke="lineColor" stroke-width="2" stroke-linecap="round" />
    <rect
      x="25.7573"
      y="26.2422"
      width="3.33333"
      height="3.33333"
      transform="rotate(-45 25.7573 26.2422)"
      :fill="lineColor"
    />
    <rect x="7.3335" y="18.667" width="13.3333" height="6.66667" rx="2" :fill="lineColor" />
    <path
      d="M10.3335 23.6663V20.5199C10.3335 20.44 10.2445 20.3924 10.178 20.4367L9.3335 20.9997"
      :stroke="textColor"
    />
    <path
      d="M12.6665 20.9997C12.6665 20.333 13.3332 20.333 13.6665 20.333C14.4075 20.333 14.6665 21.333 13.9998 21.9997L12.6665 22.9997V23.333H14.6665"
      :stroke="textColor"
    />
    <path
      d="M16 20.333H17.6667L17 21.333C17.6667 21.333 18 21.533 18 22.333C18 23.333 17 23.333 16 23.333"
      :stroke="textColor"
    />
  </svg>
</template>

<script>
import colors from '@/stylesheets/scss/colors.module.scss';
import typography from '@/stylesheets/scss/typography.module.scss';

export default {
  props: {
    selected: { type: Boolean, default: false },
  },
  computed: {
    lineColor() {
      return this.selected ? colors.white : typography.typographyPrimary;
    },
    textColor() {
      return this.selected ? colors.primary : colors.white;
    },
  },
};
</script>
